import { useEffect, useRef } from "react"
import { ethers } from "ethers"
import type { Signer } from "ethers"
import Safe, { EthersAdapter, SafeFactory } from "@safe-global/protocol-kit"
import SafeApiKit from "@safe-global/api-kit"
import { useChainId } from "wagmi"

import { useSignerStore } from "web3/providers/SignerProvider"
import { getGnosisChainServiceUrlByChainRef } from "web3/helpers/chains"
import { customContractNetworks } from "safe/constants"

type Props = {
  address?: string
}

type Options = {
  enabled?: boolean
}

type Values = {
  safeFactory: SafeFactory | undefined | null
  safeSdk: Safe | undefined | null
  safeApiKit: SafeApiKit | undefined | null
}

export const useSafeFactory = (
  { address }: Props,
  { enabled = false }: Options,
): Values => {
  const ethAdapterRef = useRef<EthersAdapter>()

  const safeFactoryRef = useRef<SafeFactory | null>(null)
  const safeSdkRef = useRef<Safe | null>(null)
  const safeApiKitRef = useRef<SafeApiKit | null>(null)

  const { signer } = useSignerStore()

  const wagmiChainReference = useChainId()

  useEffect(() => {
    if (!address || !signer || !enabled) return

    const ethAdapter = new EthersAdapter({
      ethers,
      signerOrProvider: signer as Signer,
    })

    ethAdapterRef.current = ethAdapter

    const getSafeFactory = async () => {
      if (!ethAdapterRef.current) return

      try {
        if (safeFactoryRef.current == null) {
          const safeFactory = await SafeFactory.create({
            // @ts-expect-error TODO: again, these types from safe man...
            // refer to https://docs.safe.global/safe-core-aa-sdk/protocol-kit for
            // more info on why this is actually correct, but exposed types are incorrect
            ethAdapter: ethAdapterRef.current,
          })

          safeFactoryRef.current = safeFactory
        }
      } catch (err) {}
    }

    const getSafeApi = () => {
      const txServiceUrl =
        getGnosisChainServiceUrlByChainRef(wagmiChainReference)

      if (txServiceUrl && safeApiKitRef.current == null) {
        const safeApiKit = new SafeApiKit({
          txServiceUrl,
          // @ts-expect-error TODO: again, these types from safe man...
          ethAdapter: ethAdapterRef.current,
        })

        safeApiKitRef.current = safeApiKit
      } else {
        console.error("Invalid safe network")
      }
    }

    getSafeApi()
    getSafeFactory()
  }, [address, signer, enabled, wagmiChainReference])

  useEffect(() => {
    if (!enabled) return

    if (address && Boolean(ethAdapterRef.current)) {
      const getSafeSdk = async () => {
        try {
          if (safeSdkRef.current == null) {
            const safeSdk = await Safe.create({
              // @ts-expect-error TODO: again, these types from safe man...
              ethAdapter: ethAdapterRef.current,
              safeAddress: address,
              contractNetworks: customContractNetworks,
            })

            safeSdkRef.current = safeSdk
          }
        } catch (err) {
          console.error(err)
        }
      }

      getSafeSdk()
    }

    return
  }, [address, enabled])

  return {
    safeFactory: safeFactoryRef.current,
    safeSdk: safeSdkRef.current,
    safeApiKit: safeApiKitRef.current,
  }
}
