import { useCallback, useEffect, useRef, useState } from "react"
import type {
  MetaTransactionData,
  SafeTransactionDataPartial,
} from "@safe-global/safe-core-sdk-types"
import type {
  SafeDelegateResponse,
  SafeMultisigTransactionResponse,
} from "@gnosis.pm/safe-service-client"
import { getAddress } from "@ethersproject/address"
import type { BaseContract } from "ethers"

import { getAccountIdParams } from "web3/helpers/accountId"
import { useSafeFactory } from "organization/hooks/useSafeFactory"
import { useSignerStore } from "web3/providers/SignerProvider"
import { useMe } from "user/providers/MeProvider"

// can't import the right type from safe; fix it here
export type FixedSafeMultisigTransactionResponse = Omit<
  SafeMultisigTransactionResponse,
  "fee"
> & { fee?: string }

type BuildSafeTransactionFn = <Contract extends BaseContract, MethodArgs>(
  contract: Contract,
  method: string,
  args: MethodArgs[],
  to: string,
  value?: string,
) => SafeTransactionDataPartial

type ProposeMultiGnosisTransactionFn = (
  transactions: MetaTransactionData[],
) => Promise<string | undefined>

type TxData = { requiredParameters: any[]; expectedMethod: string }

type GetSafeTxFn = (
  safeTxHash: string,
) => Promise<FixedSafeMultisigTransactionResponse | undefined>

type OnPendingSafeTxFn = (
  safeTxHash: string | undefined,
  governanceId: string,
  txData: TxData,
) => (cb: (values: Record<string, string>) => void) => void

type Props = {
  safeId: string
  accountAddress?: string
  safeTxHash?: string
}

type Options = {
  enabled?: boolean
}

type Values = {
  proposeMultiGnosisTransaction: ProposeMultiGnosisTransactionFn
  buildSafeTransaction: BuildSafeTransactionFn
  onPendingSafeTx: OnPendingSafeTxFn
  getSafeTransaction: GetSafeTxFn
  getPendingTransactions: () => Promise<
    FixedSafeMultisigTransactionResponse[] | undefined
  >
  delegates: SafeDelegateResponse[]
  isOwner?: boolean
  isDelegate?: boolean
  pendingTransactions: FixedSafeMultisigTransactionResponse[]
}

export const useSafeTransaction = (
  { safeId, accountAddress = "" }: Props,
  { enabled = false }: Options,
): Values => {
  const [delegates, setDelegates] = useState<SafeDelegateResponse[]>([])
  const [isOwner, setIsOwner] = useState<boolean>()
  const [isDelegate, setIsDelegate] = useState<boolean>()
  const [pendingTransactions, setPendingTransactions] = useState<
    FixedSafeMultisigTransactionResponse[]
  >([])

  const checksumAddressRef = useRef<string | undefined>()
  const addressRef = useRef<string | undefined>()

  const { signer } = useSignerStore()

  const me = useMe()

  const { safeSdk, safeApiKit } = useSafeFactory(
    {
      address: me?.address ?? undefined,
    },
    { enabled },
  )

  useEffect(() => {
    if (!enabled) return

    const loadDelegates = async () => {
      if (!safeApiKit) {
        return
      }

      const { results } = await safeApiKit.getSafeDelegates({
        safeAddress: checksumAddressRef.current,
      })
      setDelegates(results)

      const hasDelegate = results.filter(
        (delegate) => delegate.delegate === accountAddress,
      )

      setIsDelegate(Boolean(hasDelegate.length))
    }

    const loadAccounDetails = () => {
      const { address } = getAccountIdParams(safeId)
      const checksumAddress = getAddress(address.toLowerCase())

      checksumAddressRef.current = checksumAddress
      addressRef.current = address
    }

    loadAccounDetails()
    loadDelegates()
  }, [accountAddress, safeApiKit, enabled, safeId])

  const getPendingTransactions = useCallback(async () => {
    if (!safeApiKit || !checksumAddressRef?.current) {
      return
    }
    try {
      const data = await safeApiKit.getPendingTransactions(
        checksumAddressRef?.current,
      )

      if (data) {
        const { results } = data
        setPendingTransactions(results)

        return results
      }

      return undefined
    } catch {
      return undefined
    }
  }, [safeApiKit])

  useEffect(() => {
    if (!enabled) return

    getPendingTransactions()
  }, [safeApiKit, enabled, getPendingTransactions])

  const getSafeTransaction = useCallback(
    async (safeTxHash: string) => {
      if (!enabled || !safeApiKit) {
        console.error("Safe API Kit is not enabled or initialized.")

        return
      }

      try {
        const safeTx = await safeApiKit.getTransaction(safeTxHash)

        return safeTx
      } catch (error) {
        console.error("Error fetching the Safe transaction:", error)

        return
      }
    },
    [enabled, safeApiKit],
  )

  useEffect(() => {
    if (!enabled) return

    const loadConstants = async () => {
      if (!accountAddress || !safeSdk) {
        return
      }

      const owner = await safeSdk.isOwner(accountAddress)
      setIsOwner(owner)
    }

    loadConstants()
  }, [safeSdk, accountAddress, enabled])

  const getTransactionNonce = useCallback(async () => {
    const hasPendingTransactions = pendingTransactions.length > 0
    const nextSafeNonce = await safeSdk?.getNonce()

    // - ensure that the nonce is always greater than the highest pending
    //   tx nonce. don't assume we get pending txs back in order by nonce.
    // - if there's no pending tx use the next safe nonce returned from safe
    const nonce = hasPendingTransactions
      ? Math.max(...pendingTransactions.map((tx) => tx.nonce)) + 1
      : nextSafeNonce

    return nonce
  }, [safeSdk, pendingTransactions])

  const proposeMultiGnosisTransaction =
    useCallback<ProposeMultiGnosisTransactionFn>(
      async (transactions) => {
        const signerAddress = await signer?.getAddress()

        if (
          !signerAddress ||
          !safeSdk ||
          !safeApiKit ||
          !enabled ||
          !addressRef?.current
        ) {
          return
        }

        const nonce = await getTransactionNonce()

        const options = {
          nonce,
        }

        const safeMultiTransactions = await safeSdk.createTransaction({
          safeTransactionData: transactions,
          options,
        })
        const safeMultiTxHash = await safeSdk.getTransactionHash(
          safeMultiTransactions,
        )
        const senderMultiSignature = await safeSdk.signTransactionHash(
          safeMultiTxHash,
        )

        if (
          !safeMultiTransactions ||
          !safeMultiTxHash ||
          !senderMultiSignature
        ) {
          return
        }

        await safeApiKit.proposeTransaction({
          safeAddress: addressRef?.current,
          // @ts-expect-error: TODO: these types from safe man...
          safeTransactionData: safeMultiTransactions.data,
          safeTxHash: safeMultiTxHash,
          senderAddress: signerAddress,
          senderSignature: senderMultiSignature.data,
        })

        return safeMultiTxHash
      },
      [signer, safeSdk, safeApiKit, getTransactionNonce, enabled],
    )

  // TODO(dan): maybe this can be rolled into proposeMultiGnosisTransaction?
  // TODO(dave): maybe we use typechain, and the "populateTransaction" methods here to get helpful types
  const buildSafeTransaction = useCallback<BuildSafeTransactionFn>(
    (contract, method, args, to, value = "0"): SafeTransactionDataPartial => {
      return {
        to,
        data: contract.interface.encodeFunctionData(method, args),
        value,
      }
    },
    [],
  )

  // TODO(dan): how can we apply something similar as onPendingSafeTx to executed safe tx?
  //   - don't have websockets, so how do we know when the tx is executed from outside of tally?
  //     - it appears that the indexer picks up the tx and updates the db (need to verify)
  //     - does that mean we're fine just writing a record to the db and then waiting for the indexer to update it?
  //   - if executing from within tally, not a problem cuz we can `wait()` on the tx just like we do with normal web3 txs
  const onPendingSafeTx = useCallback<OnPendingSafeTxFn>(
    (safeTxHash, governanceId) => {
      return async (cb) => {
        if (!safeTxHash) {
          return
        }

        const tx: FixedSafeMultisigTransactionResponse | undefined =
          await safeApiKit?.getTransaction(safeTxHash)

        if (!tx || tx?.isExecuted || !tx?.data || !tx?.safe) {
          return
        }

        const data:
          | {
              method: string
              parameters: { name: string; type: string; value: string }[]
            }
          | undefined = await safeApiKit?.decodeData(tx.data)

        const values = {
          safe: tx.safe,
          safeTxHash,
          governanceId,
          tokenId: governanceId,
          ...data?.parameters.reduce(
            (acc, param) => ({ ...acc, [param.name]: param.value }),
            {},
          ),
        }

        return cb(values)
      }
    },
    [safeApiKit],
  )

  return {
    proposeMultiGnosisTransaction,
    buildSafeTransaction,
    onPendingSafeTx,
    getSafeTransaction,
    getPendingTransactions,
    delegates,
    isOwner,
    isDelegate,
    pendingTransactions,
  }
}
