import { getBaseSepoliaReference } from "web3/helpers/chainReference"

const BASE_SEP_REFERENCE = getBaseSepoliaReference()

export const customContractNetworks = {
  // data obtained from https://github.com/safe-global/safe-deployments/tree/main/src/assets/v1.3.0
  // v1.3.0 seems to be the standard for deployed safes
  // base sepolia deployments are seen in the "eip155" sections of the relevant files
  [BASE_SEP_REFERENCE]: {
    safeMasterCopyAddress: "0xfb1bffC9d739B8D520DaF37dF666da4C687191EA",
    safeProxyFactoryAddress: "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
    multiSendAddress: "0x998739BFdAAdde7C933B942a68053933098f9EDa",
    multiSendCallOnlyAddress: "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
    fallbackHandlerAddress: "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
    signMessageLibAddress: "0x98FFBBF51bb33A056B08ddf711f289936AafF717",
    createCallAddress: "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
    simulateTxAccessorAddress: "0x727a77a074D1E6c4530e814F89E618a3298FC044",
  },
}
