import type { FC, ReactNode } from "react"
import React, { useState, createContext, useEffect, useContext } from "react"
import { useBlockNumber as useBlockNumberWagmi } from "wagmi"

type BlockNumberProviderValues = number | undefined

// @ts-expect-error It's a good practice not to give a default value even though the linter tells you so
const BlockNumberContext = createContext<BlockNumberProviderValues>({})

// eslint-disable-next-line import/no-unused-modules
export const BlockNumberProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [blockNumber, setBlockNumber] = useState<number | undefined>(undefined)

  const wagmiBlockNumber = useBlockNumberWagmi()

  useEffect(() => {
    if (wagmiBlockNumber.status !== "success") return

    setBlockNumber(wagmiBlockNumber.data)
  }, [wagmiBlockNumber])

  return (
    <BlockNumberContext.Provider value={blockNumber}>
      {children}
    </BlockNumberContext.Provider>
  )
}

// eslint-disable-next-line import/no-unused-modules
export const useBlockNumber = (): BlockNumberProviderValues => {
  const context = useContext(BlockNumberContext)

  // if (context == null) {
  //   throw new Error("useConnector must be used within a BlockNumberProvider")
  // }

  return context
}
